const template = `
	{{##def.efficiency :
		<div class="consumption-emission-container consumption-emission-container-{{=renderType}} sc-hyphenate">
			<div class="emission-list-container">
				<div class="emission-list">
					{{? it.emission.headline}}
						<dl class="emission-item-head">
							<dt class="emission-item-content">
								<h3 class="emission-item-headline audi-headline-order-3">{{=it.emission.headline}}</h3>
							</dt>
						</dl>
					{{?}}
					{{? it.emission.note && it.emission.envkv && it.emission.envkv.envkvNote}}
						<dl class="emission-item emission-item-note audi-copy-s">
							<dt class="emission-item-label">{{=it.emission.note}}</dt>
							<dd class="emission-item-value">{{=it.emission.envkv.envkvNote}}</dd>
						</dl>
					{{?}}
					{{? it.emission.eecLabel}}
						<dl class="emission-item emission-item-eeclabel audi-copy-s">
							<dt class="emission-item-label">{{=it.emission.eecLabel}}</dt>
						</dl>
					{{?}}
					{{? it.emission.intro}}
						<dl class="emission-sub-item">
							<dt class="emission-sub-item-intro audi-copy-s">
								<span>{{=it.emission.intro}}</span>
							</dt>
						</dl>
					{{?}}
					{{? it.emission.envkv && !!it.emission.envkv.efficiencyImage}}
						<dl class="emission-item">
							<div class="emission-image-wrap">
								<span class="emission-image" style="background-image: url('{{=it.emission.envkv.efficiencyImage}}');"></span>
							</div>
						</dl>
					{{?}}
					{{? it.emission.envkv.consumptionTax}}
						<dl class="emission-sub-item emission-sub-item-tax audi-copy-s">
							<dt class="emission-sub-item-label">{{=it.emission.envkv.consumptionTax.label}}</dt>
							<dd class="emission-sub-item-value">{{=it.emission.envkv.consumptionTax.price.formatted}}</dd>
						</dl>
					{{?}}
					{{? it.emission.envkv.fuelCosts && it.emission.envkv.mileageData.infoText && it.emission.envkv.mileageData.formatted}}
						<dl class="emission-sub-item emission-sub-item-fuelcosts audi-copy-s">
							<dt class="emission-sub-item-label">
								<p>{{=it.emission.envkv.mileageData.infoText}} {{=it.emission.envkv.mileageData.formatted}}:</p>
								<p>{{=it.emission.envkv.fuelCosts.label}}</p>
							</dt>
							<dd class="emission-sub-item-value">{{=it.emission.envkv.fuelCosts.price.formatted}}</dd>
						</dl>
					{{?}}
					{{? it.phevEmissionData && it.phevEmissionData.fuelCosts && it.phevEmissionData.fuelCosts.price && it.phevEmissionData.fuelCosts.price.formatted && it.phevEmissionData.fuelCosts.label}}
						<dl class="emission-sub-item audi-copy-s">
							<dt class="emission-sub-item-label">
								<p>{{=it.phevEmissionData.fuelCosts.label}}</p>
							</dt>
							<dd class="emission-sub-item-value">{{=it.phevEmissionData.fuelCosts.price.formatted}}</dd>
						</dl>
					{{?}}
					{{? it.emission.envkv.date && it.emission.envkv.date.label && it.emission.envkv.date.label !=='' && it.emission.envkv.date.value && it.emission.envkv.date.value !== ''}}
						<dl class="emission-sub-item audi-copy-s">
							<span class="emission-date">{{=it.emission.envkv.date.label}}: {{=it.emission.envkv.date.value}}</span>
						</dl>
					{{?}}
				</div>
			</div>
		</div>
	#}}


	{{? it.headline}}
		<h2 class="consumption-emission-data-headline consumption-emission-data-headline-page audi-headline-order-3">{{=it.headline}}</h2>
		<h2 class="consumption-emission-data-headline consumption-emission-data-headline-layer audi-headline-order-2">{{=it.headline}}</h2>
	{{?}}
	{{? it.consumption && it.consumption.length > 0 }}
		{{? it.consumption.length > 1 }}
			<nav class="consumption-switch">
			{{~ it.consumption :consumption}}
				<a class="consumption-switch-tab consumption-j-switch-tab audi-copy-m {{=consumption.tabState}}" data-show-fuel="{{=consumption.type}}">{{=consumption.headline}}</a>
			{{~}}
			</nav>
		{{?}}
		<div class="consumption-emission-container sc-hyphenate">
			{{~ it.consumption :consumption}}
				<div class="consumption-list-container consumption-j-list-container {{=consumption.tabState}}" data-fuel-type="{{=consumption.type}}">
					{{var rangeRendered = false;}}
					{{~ consumption.items :item :index}}
						<div class="consumption-list" data-consumption-type="{{=item.type}}">
							<dl class="consumption-item-head">
								<dt class="consumption-item-content">
									<h3 class="consumption-item-headline audi-headline-order-3">{{=item.headline}}</h3>
									{{? item.link && item.link.url && item.link.url !== '' && item.link.label && item.link.label !== ''}}
										<a href="{{=item.link.url}}" target="_blank" class="consumption-link audi-link-s audi-link-s--underline">{{=item.link.label}}</a>
									{{?}}
								</dt>
							</dl>
							{{~ item.values :itemValue}}
								<dl class="consumption-item audi-copy-s">
									<dt class="consumption-item-label">
										{{=itemValue.label}}
										<a href="#{{?item.type==='nefz'}}fn_emission{{??}}fn_{{=item.type}}{{?}}" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
									</dt>
									<dd class="consumption-item-value">{{=itemValue.value}} {{=item.unit}}</dd>
								</dl>
							{{~}}
							{{? item.emissionCombinedLabel && item.emissionCombinedLabel !== '' && item.emissionCombinedValue && item.emissionCombinedValue !== ''}}
								<dl class="consumption-item audi-copy-s">
									<dt class="emission-item-label">
										{{=item.emissionCombinedLabel}}
										<a href="#{{?item.type==='nefz'}}fn_emission{{??}}fn_{{=item.type}}{{?}}" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
									</dt>
									<dd class="emission-item-value">{{=item.emissionCombinedValue}}</dd>
								</dl>
							{{?}}
						</div>
						{{? ((consumption.type === 'electrical' || it.engineType === 'OVC_HEV') && !rangeRendered && it.electricalRange && it.electricalRange.isElectrical)}}
							<div class="consumption-list" data-consumption-type="range">
								<dl class="consumption-item-head">
									<dt class="consumption-item-content">
										<h3 class="consumption-item-headline audi-headline-order-3">{{=it.electricalRange.headline}}</h3>
									</dt>
								</dl>
								{{~ it.electricalRange.items :itemValue}}
									<dl class="consumption-item audi-copy-s">
										<dt class="consumption-item-label">
											{{=itemValue.label}}
											{{? itemValue.type === 'city'}}
												<a href="#fn_wltp_electric_range" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
											{{?}}
										</dt>
										<dd class="consumption-item-value">{{=itemValue.value}}</dd>
									</dl>
								{{~}}
							</div>
							{{rangeRendered = true;}}
						{{?}}

						{{? item.type === 'nefz'}}
							{{? it.emission && it.emission.envkv && Object.keys(it.emission.envkv).length > 0 }}
								{{ let renderType = "mobile"; }}
								{{#def.efficiency}}
							{{?}}
						{{?}}
					{{~}}
				</div>
			{{~}}
		</div>
	{{?}}

	{{? it.hasNefz && it.emission && it.emission.envkv && Object.keys(it.emission.envkv).length > 0 }}
		{{ let renderType = "desktop"; }}
		{{#def.efficiency}}
	{{?}}
`;
export {template as consumptionEmissionTemplate};
