import {OBJECT_UTILS, LIST_UTILS} from '../../stockcars-utils-bundle';

const CONSUMPTION_BLACKLIST = SETUPS.get('stockcar.consumption.blacklist');

class ConsumptionEmissionPhevDataClass {
	/**
	 * get phev data
	 * @param {object} vehicleData_ vehicle data
	 * @param {object} emissionData_ emission data
	 * @returns {object} return object
	 */
	static getPhevData(vehicleData_ = {}, emissionData_ = {}) {
		const consumptionData = [];

		const emissionRepresentation = SETUPS.get('stockcar.emission.representation');
		const wltpFirst = emissionRepresentation[0] === 'wltp';

		const showWltp = emissionData_.hasWltp && !!SETUPS.get('stockcar.consumption.emission.wltpShow') && !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'consumption-wltp');
		const showNEDC = emissionData_.hasNefz && !!SETUPS.get('stockcar.consumption.emission.nefzShow') && !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'consumption-nefz');

		let wltpIndex = showWltp && wltpFirst ? 0 : 1;
		let nedcIndex = (showNEDC && !wltpFirst) || (showNEDC && wltpFirst && !showWltp) ? 0 : 1;

		if (!!vehicleData_.io) {
			vehicleData_.io.fuels.forEach((fuel, index) => { // eslint-disable-line max-statements
				if (fuel.fuel !== 'ELECTRICAL') {
					consumptionData.items = [];
					if (showNEDC) {
						consumptionData.items.push(ConsumptionEmissionPhevDataClass.getNedcObject(fuel));

						if (!LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, [fuel.fuel.toLowerCase(), `${fuel.fuel.toLowerCase()}-consumption-nefz`])) {
							ConsumptionEmissionPhevDataClass.getItemLabelAndValue(consumptionData, fuel, 'nedc', 0, 'sc.consumption.nefz.combined.label');
						}
					}
					if (showWltp) {
						if (wltpFirst) {
							consumptionData.items.unshift(ConsumptionEmissionPhevDataClass.getWltpObject(fuel));
						}
						else {
							consumptionData.items.push(ConsumptionEmissionPhevDataClass.getWltpObject(fuel));
						}

						if (!LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, [fuel.fuel.toLowerCase(), `${fuel.fuel.toLowerCase()}-consumption-wltp`])) {
							ConsumptionEmissionPhevDataClass.getItemLabelAndValue(consumptionData, fuel, 'wltp', wltpIndex, 'sc.consumption.wltp.combined.label');
						}
					}
					consumptionData.headline = window.i18n[ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.tiles.fuel.type.${fuel.fuel}.label`)] || ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.tiles.fuel.type.${fuel.fuel}.label`);
					consumptionData.type = fuel.fuel.toLowerCase() || '';
					consumptionData.tabState = (index === 0 || emissionData_.engineType === 'OVC_HEV') ? 'active' : '';
				}
				else if (fuel.fuel === 'ELECTRICAL') {
					if (emissionData_.hasNefz && !!SETUPS.get('stockcar.consumption.emission.nefzShow') && !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, ['consumption-nefz', fuel.fuel.toLowerCase(), `${fuel.fuel.toLowerCase()}-consumption-nefz`])) {
						ConsumptionEmissionPhevDataClass.getItemLabelAndValue(consumptionData, fuel, 'nedc', nedcIndex, 'sc.consumption.nefz.combined.electrical.label');
					}
					if (emissionData_.hasWltp && !!SETUPS.get('stockcar.consumption.emission.wltpShow') && !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, ['consumption-wltp', fuel.fuel.toLowerCase(), `${fuel.fuel.toLowerCase()}-consumption-wltp`])) {
						ConsumptionEmissionPhevDataClass.getItemLabelAndValue(consumptionData, fuel, 'wltp', wltpIndex, 'sc.consumption.wltp.combined.electrical.label');
					}
				}
			});
		}
		return [consumptionData];
	}

	static getItemLabelAndValue(consumptionData, fuel, type, index, label) { // eslint-disable-line max-params
		if (ConsumptionEmissionPhevDataClass.getCombinedValue(fuel, type) !== '') {
			consumptionData.items[index].values.push({
				label: window.i18n[label],
				value: ConsumptionEmissionPhevDataClass.getCombinedValue(fuel, type)
			});
		}
	}

	static getCombinedValue(fuel, type) {
		const combined = OBJECT_UTILS.getNestedObject(fuel, `consumption.${type}.dataSets`.split('.')) ? fuel.consumption[type].dataSets.filter(item => item.values.hasOwnProperty('combined')) : '';
		const unit = OBJECT_UTILS.getNestedObject(fuel, `consumption.${type}.unit`.split('.')) ? fuel.consumption[type].unit : '';
		let value = '';
		if (combined && combined.length) {
			combined.forEach(item => {
				if (item.hasOwnProperty('em') && item.em === 'WEIGHTED') {
					value = `${item.values.combined} ${unit}`;
				}
				else {
					value = `${item.values.combined} ${unit}`;
				}
			});
		}
		return value;
	}

	static getEmissionValue(fuel, type) {
		if (fuel.emission && fuel.emission.co2 && fuel.emission.co2[type] && fuel.emission.co2[type].consolidated) {
			return `${fuel.emission.co2[type].consolidated} ${fuel.emission.co2[type].unit}`;
		}
	}

	/**
	 * generate i18n key
	 * @static
	 * @param {string} patternString - i18n string
	 * @returns {string} modified i18n string
	 */
	static generatei18nKey(patternString) {
		return patternString.replace(/_/g, '.').toLowerCase();
	}

	static getNedcObject(fuel) {
		return {
			type: 'nefz',
			label: window.i18n[ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.consumption.item.nefz.label`)] || ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.consumption.item.nefzlabel`),
			unit: '',
			headline: window.i18n[`sc.details.techdata.nefz.headline`] || ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.details.techdata.nefz.headline`),
			link: {
				url: SETUPS.get(`stockcar.consumption.emission.nefzUrl`),
				label: window.i18n[`sc.details.techdata.nefz.link.text`] || `sc.details.techdata.nefz.link.text`
			},
			values: [],
			emissionCombinedLabel: window.i18n['sc.technical-data.emissionCombined'] || 'sc.technical-data.emissionCombined',
			emissionCombinedValue: (!LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, ['emission-combined', 'emission-nefz-combined'])) ? ConsumptionEmissionPhevDataClass.getEmissionValue(fuel, 'nedc') : ''
		};
	}

	static getWltpObject(fuel) {
		return {
			type: 'wltp',
			label: window.i18n[ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.consumption.item.wltp.label`)] || ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.consumption.item.wltplabel`),
			unit: '',
			headline: window.i18n[`sc.details.techdata.wltp.headline`] || ConsumptionEmissionPhevDataClass.generatei18nKey(`sc.details.techdata.wltp.headline`),
			link: {
				url: SETUPS.get(`stockcar.consumption.emission.wltpUrl`),
				label: window.i18n[`sc.details.techdata.wltp.link.text`] || `sc.details.techdata.wltp.link.text`
			},
			values: [],
			emissionCombinedLabel: window.i18n['sc.technical-data.emissionCombined'] || 'sc.technical-data.emissionCombined',
			emissionCombinedValue: (!LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, ['emission-combined', 'emission-wltp-combined'])) ? ConsumptionEmissionPhevDataClass.getEmissionValue(fuel, 'wltp') : ''
		};
	}
}

export {ConsumptionEmissionPhevDataClass};
