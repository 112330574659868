const template = `
	<div class="sc-car-tile-consumption-legend">
		<p class="sc-car-tile-consumption-emission sc-car-tile-efficiency-with-icon audi-copy-s">
			<span class="sc-car-tile-consumption-icon">
				<svg class="nm-icon-efficiency-class">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/audi-efficiency-class-c.svg#audi-efficiency-class-c"}}></use>
				</svg>
			</span>
			<span class="sc-car-tile-efficiency-label">
				<span>
					/* extra span for text centering with flexbox */
					{{=window.i18n['sc.result.eec.link']||'Effizienzklasse'}}
				</span>
			</span>
		</p>
		<p class="sc-car-tile-consumption-emission sc-car-tile-efficiency-with-icon audi-copy-s">
			<span class="sc-car-tile-consumption-icon">
				<svg class="nm-icon-efficiency-class">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/engine-data/consumption-small.svg#consumption-small"}}></use>
				</svg>
			</span>
			<span class="sc-car-tile-efficiency-label">
				<span>
					/* extra span for text centering with flexbox */
					{{=window.i18n['sc.result.consumptionDisplay']||''}}<a href="#fn_emission" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
				</span>
			</span>
		</p>
		<p class="sc-car-tile-consumption-emission sc-car-tile-efficiency-with-icon audi-copy-s">
			<span class="sc-car-tile-consumption-icon">
				<svg class="nm-icon-efficiency-class">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/engine-data/consumption-electrical-small.svg#consumption-electrical-small"}}></use>
				</svg>
			</span>
			<span class="sc-car-tile-efficiency-label">
				<span>
					/* extra span for text centering with flexbox */
					{{=window.i18n['sc.result.consumptionElectricalDisplay']||'sc.result.consumptionElectricalDisplay'}}<a href="#fn_emission" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
				</span>
			</span>
		</p>
		<p class="sc-car-tile-consumption-emission sc-car-tile-efficiency-with-icon audi-copy-s">
			<span class="sc-car-tile-consumption-icon">
				<svg class="nm-icon-efficiency-class">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/engine-data/co2-emission-small.svg#co2-emission-small"}}></use>
				</svg>
			</span>
			<span class="sc-car-tile-efficiency-label">
				<span>
					{{=window.i18n['sc.result.emissionDisplay']||''}}<a href="#fn_emission" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
				</span>
			</span>
		</p>
		<p class="sc-car-tile-consumption-emission sc-car-tile-efficiency-with-icon audi-copy-s">
			<span class="sc-car-tile-consumption-icon">
				<svg class="nm-icon-efficiency-class">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/electric-range-small.svg#electric-range-small"}}></use>
				</svg>
			</span>
			<span class="sc-car-tile-efficiency-label">
				<span>
					{{=window.i18n['sc.result.electricalRange']||''}}
				</span>
			</span>
		</p>
	</div>
`;

export {template as consumptionEmissionLegendTemplate};
