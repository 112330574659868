import {consumptionTilesElementEfficiencyClass} from './consumption-tiles-element-efficiency-class';
import {consumptionTilesElementConsumptionDisplayTemplate} from './consumption-tiles-element-consumption-display-template';
import {consumptionTilesElementElectricalRangeTemplate} from './consumption-tiles-element-electrical-range-template';
import {consumptionTilesElementEmissionDisplayTemplate} from './consumption-tiles-element-emission-display-template';

const template = `
	<div class="sc-car-tile-consumption">
		<div class="sc-car-tile-consumption-labels">
			{{? it}}
				{{~it.order :item}}
					{{? item === 'efficiency' && !!it.efficiencyClass }}
						${consumptionTilesElementEfficiencyClass}
					{{?? item === 'consumption' && it.consumptionDisplay.length}}
						${consumptionTilesElementConsumptionDisplayTemplate}
					{{?? item === 'emission' && it.emissionDisplay.length}}
						${consumptionTilesElementEmissionDisplayTemplate}
					{{?? item === 'electrical-range' && Object.keys(it.electricalRange).length}}
						${consumptionTilesElementElectricalRangeTemplate}
					{{?}}
				{{~}}
			{{?}}
		</div>
		{{? it.io && !!window.i18n['sc.tiles.consumption.link.text']}}
			<button class="sc-car-tile-consumption-link audi-link-s audi-link-s--arrow js-modal-layer-link sc-js-eec-link" data-layer-type="ModalConsumptionEmissionLayerElement" data-id="{{=it.id}}">
				<span class="audi-link-s__text">{{=window.i18n['sc.tiles.consumption.link.text']}}</span>
				<svg class="audi-link-s__icon">
					<use xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
				</svg>
			</button>
		{{?}}
	</div>
`;

export {template as consumptionTilesTemplate};
