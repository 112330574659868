import {dom} from 'core-utils';
import {ScsModelBase} from '../../stockcars-bundle';

class ConsumptionEmissionStateClass extends ScsModelBase {
	/**
	 * constructor
	 * @returns {void} void
	 */
	constructor() {
		super();
		this._state = [];
		this._notifyDebounced = dom.debounce(this.notifyObservers, 100).bind(this);
		this._resetStateDebounced = dom.debounce(this.resetState, 100).bind(this);
	}

	/**
	 * getter for state
	 * @returns {null|object} state
	 */
	get state() {
		let state = {
			emissionDisplay: false,
			consumptionDisplay: false,
			consumptionElectricalDisplay: false,
			efficiencyClass: false,
			electricalRange: false
		};
		this._state.forEach((value) => {
			if (value.emissionDisplay) {
				state.emissionDisplay = true;
			}
			if (value.consumptionDisplay) {
				state.consumptionDisplay = true;
			}
			if (value.consumptionElectricalDisplay) {
				state.consumptionElectricalDisplay = true;
			}
			if (value.efficiencyClass) {
				state.efficiencyClass = true;
			}
			if (value.electricalRange) {
				state.electricalRange = true;
			}
		});
		return state;
	}

	resetState() {
		this._state = [];
	}

	/**
	 * setter for state
	 * @param {object} state state object
	 * @returns {void} void
	 */
	set state(state) {
		this._state.push(state);
		this._notifyDebounced(state.contextContainer);
		this._resetStateDebounced();
	}
}

const consumptionEmissionState = new ConsumptionEmissionStateClass();
export {consumptionEmissionState, ConsumptionEmissionStateClass};
