import {template, dom} from 'core-utils';
import {STCK_STORE} from '@oneaudi/stck-store';
import {ConsumptionEmissionPhevDataClass} from './consumption-emission-phev-data';
import {consumptionEmissionTemplate} from './consumption-emission-template';
import {vehiclesController} from '../../stockcars-bundle';
import {OBJECT_UTILS, LIST_UTILS} from '../../stockcars-utils-bundle';

const CONSUMPTION_BLACKLIST = SETUPS.get('stockcar.consumption.blacklist');

export default class ConsumptionEmissionElement extends HTMLElement {

	constructor() {
		super();
	}

	static get defaults() {
		return {
			consumptionSwitch: '.consumption-switch',
			consumptionListContainer: '.consumption-j-list-container',
			techDataWrapper: '.sc-md-details-technical-data',
			techDataConsumptionItem: '.sc-techdata-list-initial-data-consumption-item',
			techDataEecItem: '.sc-details-technical-data-eec',
			techDataEecNote: '.sc-details-technical-data-eec-note',
			techDataConsumptionList: '.sc-techdata-list-consumption',
			hidden: 'sc-hidden',
			activeClass: 'active',
			phevIdentifier: 'OVC_HEV'
		};
	}

	async connectedCallback() {
		try {
			const preparedData = await this._getPreparedData();
			this._render(preparedData);
			this._consumptionSwitch = dom.getElement(ConsumptionEmissionElement.defaults.consumptionSwitch, this);
			this._addEventListener();
		}
		catch (err) {
			console.log(`Consumption emission element could not be rendered: ${err}`);
			this.setAttribute('data-state', 'inactive');
			setTimeout(() => { this.innerHTML = ''; }, 2000);
		}
	}

	disconnectedCallback() {
		this._removeEventListener();
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {object} emissionData
	 */
	async _getPreparedData() {
		const _vehicleData = await this._getVehicleData();
		const _data = {};
		_data.io = _vehicleData.io ? {..._vehicleData.io} : {};
		_data.headline = window.i18n['sc.consumption.headline'] || 'sc.consumption.headline';
		_data.emission = !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'efficiency') ? this._getEmissionData(_vehicleData) : '';
		_data.engineType = OBJECT_UTILS.getNestedObject(_vehicleData, 'io.engineType'.split('.')) || '';
		_data.envkvIOData = _vehicleData.envkvIOData ? {..._vehicleData.envkvIOData} : {};
		_data.phevEmissionData = this._getPhevElectricalFuelData(_data);
		_data.hasNefz = OBJECT_UTILS.getNestedObject(_vehicleData, 'io.hasNedc'.split('.')) || false;
		_data.hasWltp = OBJECT_UTILS.getNestedObject(_vehicleData, 'io.hasWltp'.split('.')) || false;
		_data.consumption = _data.engineType === ConsumptionEmissionElement.defaults.phevIdentifier ? ConsumptionEmissionPhevDataClass.getPhevData(_vehicleData, _data) : this._getConsumptionData(_vehicleData, _data);
		_data.electricalRange = !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, ['electrical', 'electrical-range']) ? this._getElectricalRanges(_data.io) : '';

		return _data;
	}

	_getPhevElectricalFuelData(vehicleData) {
		if (vehicleData.engineType !== ConsumptionEmissionElement.defaults.phevIdentifier) {
			return null;
		}

		try {
			return vehicleData.envkvIOData.fuels.find((fuelType) => fuelType.fuel === 'ELECTRICAL');
		}
		catch (e) {
			console.log('Phev Vehicle has no electrial fuel entry');
			return null;
		}
	}

	/**
	 * add event listener
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListener() {
		this._consumptionSwitchClickHandler = this._consumptionSwitchClickHandler.bind(this);
		this._consumptionSwitch.addEventListener('click', this._consumptionSwitchClickHandler);
	}

	_removeEventListener() {
		if (this._consumptionSwitch && this._consumptionSwitch !== undefined) {
			this._consumptionSwitch.removeEventListener('click', this._consumptionSwitchClickHandler);
		}
	}

	_consumptionSwitchClickHandler(event) {
		if (event && event.target) {
			let consumptionSwitchButton = event.target;
			let targetType = consumptionSwitchButton.getAttribute('data-show-fuel');
			let list = dom.getElementsArray(ConsumptionEmissionElement.defaults.consumptionListContainer);
			list.forEach((listItem) => {
				let type = listItem.getAttribute('data-fuel-type');
				if (type === targetType) {
					listItem.classList.add(ConsumptionEmissionElement.defaults.activeClass);
					consumptionSwitchButton.classList.add(ConsumptionEmissionElement.defaults.activeClass);
				}
				else {
					listItem.classList.remove(ConsumptionEmissionElement.defaults.activeClass);
					if (!!consumptionSwitchButton.nextElementSibling) {
						consumptionSwitchButton.nextElementSibling.classList.remove(ConsumptionEmissionElement.defaults.activeClass);
					}
					else if (!!consumptionSwitchButton.previousElementSibling) {
						consumptionSwitchButton.previousElementSibling.classList.remove(ConsumptionEmissionElement.defaults.activeClass);
					}
				}
			});
		}
	}

	/**
	 * get vehicle data
	 * @private
	 * @returns {object} vehicleData json
	 */
	async _getVehicleData() {
		const data = Object.assign({}, this.dataset);
		let vehicleData = {};
		if (data && data.vehicleId) {
			vehicleData = await vehiclesController.loadVehicleById(data.vehicleId);
		}
		return vehicleData;
	}

	/**
	 * hide consumption emission in tech data
	 * @private
	 * @returns {void} void
	 */
	_hideConsumptionEmissionInTechData() {
		let techDataDiv = dom.getElement(ConsumptionEmissionElement.defaults.techDataWrapper);
		if (dom.isElement(techDataDiv)) {
			let elements = [ConsumptionEmissionElement.defaults.techDataConsumptionItem, ConsumptionEmissionElement.defaults.techDataEecItem, ConsumptionEmissionElement.defaults.techDataEecNote, ConsumptionEmissionElement.defaults.techDataConsumptionList];
			elements.forEach((element) => {
				let items = dom.getElementsArray(element, techDataDiv);
				items.forEach((item) => {
					if (!!item) {
						item.classList.add(ConsumptionEmissionElement.defaults.hidden);
					}
				});
			});

		}
	}

	/**
	 * getConsumptionData
	 * @private
	 * @param {object} vehicleData_ vehicle data
	 * @param {object} emissionData_ emission data
	 * @returns {object} return object
	 */
	_getConsumptionData(vehicleData_ = {}, emissionData_ = {}) {
		const consumptionData = [];
		if (!!vehicleData_.io) {
			const ioData = vehicleData_.io.fuels;
			if (ioData.length > 0) {
				this._hideConsumptionEmissionInTechData();
			}
			ioData.filter((data) => {
				return !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, data.fuel.toLowerCase());
			}).forEach((data, index) => {
				const fuel = {};
				fuel.headline = window.i18n[ConsumptionEmissionElement._generatei18nKey(`sc.tiles.fuel.type.${data.fuel}.label`)] || ConsumptionEmissionElement._generatei18nKey(`sc.tiles.fuel.type.${data.fuel}.label`);
				fuel.type = data.fuel.toLowerCase() || '';
				fuel.tabState = (index === 0 || emissionData_.engineType === 'OVC_HEV') ? ConsumptionEmissionElement.defaults.activeClass : '';
				fuel.items = this._getConsumptionValues(data, emissionData_);
				consumptionData.push(fuel);
			});
		}

		return consumptionData;
	}

	/**
	 * _getElectricalRanges
	 * @private
	 * @param {object} io_ - vehicle io data
	 * @returns {object} return range
	 */
	_getElectricalRanges(io_) {
		const range = {};
		range.isElectrical = false;
		if (io_ && io_.fuels) {
			io_.fuels.forEach(electrical => {
				if (electrical.fuel === 'ELECTRICAL') {
					const unit = OBJECT_UTILS.getNestedObject(electrical, 'range.wltp.unit'.split('.')) || '';
					range.isElectrical = true;
					range.headline = window.i18n['sc.electrical.range.wltp.headline'] || 'sc.electrical.range.wltp.headline';
					if (OBJECT_UTILS.getNestedObject(electrical, 'range.wltp.dataSets'.split('.'))) {
						electrical.range.wltp.dataSets.forEach(data => {
							if (data.isDefault) {
								range.items = [];
								if (!LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'electrical-range-city')) {
									range.items.push({
										'type': 'city',
										'label': window.i18n['sc.electrical.range.city.label'] || 'sc.electrical.range.city.label',
										'value': data.values.city + ' ' + unit
									});
								}
								if (!LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'electrical-range-combined')) {
									range.items.push({
										'type': 'combined',
										'label': window.i18n['sc.electrical.range.combined.label'] || 'sc.electrical.range.combined.label',
										'value': data.values.combined + ' ' + unit
									});
								}
							}
						});
					}
				}
			});
		}
		return range;
	}

	/**
	 * get consumption values
	 * @private
	 * @param {object} data - vehicle fuel data
	 * @param {object} emissionData_ - vehicle emission data
	 * @returns {array} consumptionValues
	 */
	_getConsumptionValues(data, emissionData_) {
		let consumptionValues = [];
		let ioValues = [];
		const emissionRepresentation = SETUPS.get('stockcar.emission.representation');

		if (emissionData_.hasNefz && !!SETUPS.get('stockcar.consumption.emission.nefzShow') && !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, [`${data.fuel.toLowerCase()}-consumption-nefz`, 'consumption-nefz'])) {
			ioValues.push('nedc');
		}
		if (emissionData_.hasWltp && !!SETUPS.get('stockcar.consumption.emission.wltpShow') && !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, [`${data.fuel.toLowerCase()}-consumption-wltp`, 'consumption-wltp'])) {
			if (emissionRepresentation[0] === 'wltp') {
				ioValues.unshift('wltp');
			}
			else {
				ioValues.push('wltp');
			}

		}


		if (data) {
			ioValues.forEach((value) => {
				const type = (value === 'nedc' ? 'nefz' : value);
				let consumptionData = this._getConsumptionDataObject(type, data, value);
				consumptionValues.push(consumptionData);
			});
		}
		return consumptionValues;
	}

	/**
	 * get consumption data object
	 * @private
	 * @param {string} type - nefz || wltp
	 * @param {object} data - vehicle fuel data
	 * @param {object} value - nedc || wltp
	 * @returns {array} consumptionData
	 */
	_getConsumptionDataObject(type, data, value) {
		let consumptionData = {};
		consumptionData.type = type;
		consumptionData.label = window.i18n[ConsumptionEmissionElement._generatei18nKey(`sc.consumption.item.${type}.label`)] || ConsumptionEmissionElement._generatei18nKey(`sc.consumption.item.${type}label`);
		consumptionData.unit = (data.consumption && data.consumption[value] && data.consumption[value].unit) ? data.consumption[value].unit : '';
		consumptionData.headline = window.i18n[`sc.details.techdata.${type}.headline`] || ConsumptionEmissionElement._generatei18nKey(`sc.details.techdata.${type}.headline`);
		consumptionData.link = {};
		consumptionData.link.url = SETUPS.get(`stockcar.consumption.emission.${type}Url`);
		consumptionData.link.label = window.i18n[`sc.details.techdata.${type}.link.text`] || `sc.details.techdata.${type}.link.text`;
		consumptionData.emissionCombinedLabel = window.i18n['sc.technical-data.emissionCombined'] || 'sc.technical-data.emissionCombined';
		consumptionData.emissionCombinedValue = (data.emission && data.emission.co2 && data.emission.co2[value] && data.emission.co2[value].consolidated && !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, ['emission-combined', `emission-${type}-combined`, `${data.fuel.toLowerCase()}-emission-${type}-combined`, `${data.fuel.toLowerCase()}-emission-combined`])) ? `${data.emission.co2[value].consolidated} ${data.emission.co2[value].unit}` : '';
		if (data.consumption[value] && data.consumption[value].dataSets) {
			data.consumption[value].dataSets.forEach((dataSet) => {
				if (dataSet.isDefault) {
					if (type === 'wltp') {
						const orderedValues = ['low', 'medium', 'high', 'extraHigh', 'combined', 'city'];
						consumptionData.values = [];
						orderedValues.forEach((key) => {
							if (dataSet.values && dataSet.values[key] && !LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, [`${data.fuel.toLowerCase()}-consumption-${type}-${key.toLowerCase().replace('_', '')}`, `consumption-${type}-${key.toLowerCase().replace('_', '')}`])) {
								consumptionData.values.push(this._getConsumptionValueObject(dataSet, type, key));
							}
						});
					}
					else {
						consumptionData.values = Object.keys(dataSet.values).filter((key) => {
							return (!LIST_UTILS.hasAnyInList(CONSUMPTION_BLACKLIST, [`${data.fuel.toLowerCase()}-consumption-${type}-${key.toLowerCase().replace('_', '')}`, `consumption-${type}-${key.toLowerCase().replace('_', '')}`]));
						}).map((key) => {
							return this._getConsumptionValueObject(dataSet, type, key);
						});
					}
				}
			});
		}
		return consumptionData;
	}

	/**
	 * get consumption value object
	 * @private
	 * @param {object} dataSet - nefz or wltp data
	 * @param {string} type - nefz || wltp
	 * @param {object} key - dataSet data key
	 * @returns {object} consumptionData.values
	 */
	_getConsumptionValueObject(dataSet, type, key) {
		return {
			label: window.i18n[ConsumptionEmissionElement._generatei18nKey(`sc.consumption.${type}.${key}.label`)] || ConsumptionEmissionElement._generatei18nKey(`sc.consumption.${type}.${key}.label`),
			value: dataSet.values[key],
			key: key
		};
	}

	/**
	 * generate i18n key
	 * @static
	 * @param {string} patternString - i18n string
	 * @returns {string} modified i18n string
	 */
	static _generatei18nKey(patternString) {
		return patternString.replace(/_/g, '.').toLowerCase();
	}

	/**
	 * get emission data
	 * @private
	 * @param {object} vehicleData vehicleData
	 * @returns {object} emission data
	 */
	_getEmissionData(vehicleData) {
		let envkvData = (vehicleData.envkvData && vehicleData.envkvData.consumptionTax) ? vehicleData.envkvData : null;
		// consumptionTax FIX using data from new envkvIOData node!!
		if (envkvData && vehicleData.envkvIOData && vehicleData.envkvIOData.consumptionTax){
			vehicleData.envkvData.consumptionTax.price.formatted = vehicleData.envkvIOData.consumptionTax.price.formatted;
			vehicleData.envkvData.creationDateFormatted = vehicleData.envkvIOData.creationDateFormatted || vehicleData.envkvData.creationDateFormatted;
		}
		if (envkvData && vehicleData.envkvIOData && vehicleData.envkvIOData.fuels && vehicleData.envkvIOData.fuels.length > 0){
			if (vehicleData.engineType !== ConsumptionEmissionElement.defaults.phevIdentifier) {
				vehicleData.envkvData.fuelCosts = vehicleData.envkvIOData.fuels[0].fuelCosts;
			}
			else {
				vehicleData.envkvData.fuelCosts = vehicleData.envkvIOData.fuels.find((fuelType) => fuelType.fuel !== 'ELECTRICAL').fuelCosts;
			}
		}
		return {
			headline: window.i18n['sc.emission.headline'] || 'sc.emission.headline',
			eecLabel: window.i18n['sc.details.technical-data.eec.label'] || 'sc.details.technical-data.eec.label',
			intro: window.i18n['sc.details.technical-data.eec.introduction'] || 'sc.details.technical-data.eec.introduction',
			note: window.i18n['nemo.ui.common.note'] || 'nemo.ui.common.note',
			envkv: envkvData ? this._getEnvkvData(envkvData) : {}
		};
	}

	/**
	 * get envkv data
	 * @private
	 * @param {object} envkvData - envkv data
	 * @returns {object} modified envkv data object
	 */
	_getEnvkvData(envkvData) {
		const countryCode = STCK_STORE.state.country;
		return {
			envkvNote: window.i18n['sc.details.technical-data.envkv.note'] || 'sc.details.technical-data.envkv.note',
			efficiencyImage: (envkvData.efficiencyClass && (!!countryCode && countryCode === 'DE') && !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'eec-image')) ? `${SETUPS.get('stockcar.url.assets')}/img/eec/eec-label-${envkvData.efficiencyClass.toLowerCase()}.png` : '',
			consumptionTax: !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'tax') ? envkvData.consumptionTax : '',
			fuelCosts: !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'fuel-costs') ? envkvData.fuelCosts : '',
			mileageData: envkvData.mileageData,
			date: {
				label: window.i18n['sc.details.technical-data.eec.date.label'] || 'sc.details.technical-data.eec.date.label',
				value: !LIST_UTILS.isInList(CONSUMPTION_BLACKLIST, 'eec-date') ? envkvData.creationDateFormatted : ''
			}
		};
	}

	/**
	 * render
	 * @private
	 * @param {object} emissionData_ data to be rendered
	 * @returns {void} return nothing
	 */
	_render(emissionData_ = {}) {
		const htmlString = template.render(consumptionEmissionTemplate, emissionData_);
		this.setAttribute('data-state', 'active');
		this.innerHTML = htmlString;
	}

}
if (window.customElements.get('consumption-emission-element') === undefined) {
	window.customElements.define('consumption-emission-element', ConsumptionEmissionElement);
}
