import {appEvents} from 'core-application';
import {template, dom} from 'core-utils';
import {consumptionEmissionState} from './consumption-emission-state';
import {consumptionTilesTemplate} from './consumption-tiles-element-template';
import {vehiclesController} from '../../stockcars-bundle';
import {OBJECT_UTILS} from '../../stockcars-utils-bundle';

export default class ConsumptionTilesElement extends HTMLElement {
	constructor() {
		super();
		this._data = null;
		this._showWltp = !!SETUPS.get('stck.show.wltp.in.tiles');
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	async connectedCallback() {
		await this._initializeProperties();
		this._render();

	}


	/**
	 * get consumptionDisplay
	 * @private
	 * @param {object} data_ vehicle data
	 * @returns {array} return taxation
	 */
	getEmissionDisplayValues(data_ = {}) {
		const emissionDisplayValues = [];
		if (!!data_) {
			if (OBJECT_UTILS.getNestedObject(data_, 'io.fuels'.split('.')) && Array.isArray(data_.io.fuels)) {
				data_.io.fuels.forEach(fuel => {
					if (fuel.emission && ((this._showWltp && OBJECT_UTILS.getNestedObject(fuel, 'emission.co2.wltp.consolidated'.split('.'))) || OBJECT_UTILS.getNestedObject(fuel, 'emission.co2.nedc.consolidated'.split('.')))) {
						emissionDisplayValues.push({
							fuelType: window.i18n[`sc.tiles.fuel.type.${fuel.fuel.toLowerCase()}.label`],
							combined: window.i18n[`sc.tiles.fuel.type.${fuel.fuel.toLowerCase()}.emission.combined`],
							emission: this.getEmissionValue(fuel)
						});
					}
				});
			}
			else if (data_.emissionDisplay) {
				emissionDisplayValues.push({emission: data_.emissionDisplay});
			}
		}
		return emissionDisplayValues;
	}

	/**
	 * get emission value
	 * @private
	 * @param {object} fuel - fuel
	 * @returns {string} return emissionValue
	 */
	getEmissionValue(fuel) {
		let emissionValue = '';
		if (this._data.engineType !== 'OVC_HEV' && this._showWltp && OBJECT_UTILS.getNestedObject(fuel, 'emission.co2.wltp'.split('.'))) {
			emissionValue = `${fuel.emission.co2.wltp.consolidated} ${fuel.emission.co2.wltp.unit}`;
		}
		else if (OBJECT_UTILS.getNestedObject(fuel, 'emission.co2.nedc'.split('.'))) {
			emissionValue = `${fuel.emission.co2.nedc.consolidated} ${fuel.emission.co2.nedc.unit}`;
		}
		return emissionValue;
	}

	/**
	 * get consumptionDisplay
	 * @private
	 * @param {object} data_ vehicle data
	 * @returns {array} return consumptionDisplayValues
	 */
	getConsumptionDisplay(data_ = {}) {
		const consumptionDisplayValues = [];
		if (!!data_) {

			if (Array.isArray(OBJECT_UTILS.getNestedObject(data_, 'io.fuels'.split('.')))) {
				data_.io.fuels.forEach(fuel => {
					if (fuel.consumption && ((this._showWltp && OBJECT_UTILS.getNestedObject(fuel, 'consumption.wltp.consolidated'.split('.'))) || OBJECT_UTILS.getNestedObject(fuel, 'consumption.nedc.consolidated'.split('.')))) {
						consumptionDisplayValues.push({
							fuelType: window.i18n[`sc.tiles.fuel.type.${fuel.fuel.toLowerCase()}.label`],
							combined: window.i18n[`sc.tiles.fuel.type.${fuel.fuel.toLowerCase()}.consumption.combined`],
							consumption: this.getConsumptionValue(fuel)
						});
					}
				});
			}
			else if (data_.consumptionDisplay) {
				consumptionDisplayValues.push({consumption: data_.consumptionDisplay});
			}
		}
		return consumptionDisplayValues;
	}

	/**
	 * get consumption value
	 * @private
	 * @param {object} fuel - fuel
	 * @returns {string} return consumptionValue
	 */
	getConsumptionValue(fuel) {
		let consumptionValue = '';
		if (this._data.engineType !== 'OVC_HEV' && this._showWltp && fuel.consumption.wltp) {
			consumptionValue = `${fuel.consumption.wltp.consolidated} ${fuel.consumption.wltp.unit}`;
		}
		else if (fuel.consumption.nedc) {
			consumptionValue = `${fuel.consumption.nedc.consolidated} ${fuel.consumption.nedc.unit}`;
		}
		return consumptionValue;
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {void} void
	 */
	async _initializeProperties() {
		try {
			const vehicleData = await this._getVehicleData();
			if (vehicleData) {
				this._contextContainer = dom.closest(this, '.nm-content');
				this._processData(vehicleData);
			}
		}
		catch (err) {
			console.error(err);
			this._data = null;
		}
	}

	/**
	 * _processData
	 * @param {object} vehicleData_ vehicle data
	 * @private
	 * @returns {void} void
	 */
	_processData(vehicleData_) {
		this._data = {};
		this._data.io = {...vehicleData_.io};
		this._data.envkvData = {...vehicleData_.envkvData} || {};
		this._data.hasEnvkv = (Object.keys(this._data.envkvData).length > 0);
		this._data.id = this.dataset.vehicleId;
		this._data.engineType = (this._data.io && this._data.io.engineType) ? this._data.io.engineType : '';
		this._data.order = !!this.dataset.order ? this.dataset.order.split(',') : [];
		this._data.efficiencyClass = this.dataset.showEfficiency === 'true' ? this._getEnvkvEfficiency(vehicleData_) : '';
		this._data.consumptionDisplay = this.dataset.showConsumption === 'true' ? (this.getConsumptionDisplay(vehicleData_) || []) : [];
		this._data.emissionDisplay = this.dataset.showEmission === 'true' ? (this.getEmissionDisplayValues(vehicleData_) || []) : [];
		this._data.isElectrical = this.dataset.isElectrical === 'true';
		this._data.electricalRange = (!!this._data.isElectrical && this._data.io.fuels) ? this._getElectricalRanges(this._data.io.fuels) : {};
		this._setConsumptionState(this._data);
	}

	/**
	 * _getEnvkvEfficiency
	 * @private
	 * @param {object} vehicleData_ vehicle data
	 * @returns {object} return efficiency class
	 */
	_getEnvkvEfficiency(vehicleData_ = {}) {
		return (vehicleData_.envkvData && vehicleData_.envkvData.efficiencyClass) ? vehicleData_.envkvData.efficiencyClass : '';
	}

	/**
	 * _setConsumptionState
	 * @private
	 * @param {object} data_ vehicle data
	 * @returns {void} void
	 */
	_setConsumptionState(data_ = {}) {
		if ((!!data_.efficiencyClass || data_.consumptionDisplay.length || data_.emissionDisplay.length || !!data_.isElectrical) && Object.keys(consumptionEmissionState.state).length) {
			consumptionEmissionState.state = {
				contextContainer: this._contextContainer,
				emissionDisplay: (data_.emissionDisplay.length > 0),
				consumptionDisplay: (data_.consumptionDisplay.length > 0) && !data_.isElectrical,
				consumptionElectricalDisplay: (data_.consumptionDisplay.length > 0) && !!data_.isElectrical,
				efficiencyClass: !!data_.efficiencyClass,
				electricalRange: !!Object.keys(data_.electricalRange).length
			};
		}
	}

	/**
	 * _getElectricalRanges
	 * @private
	 * @param {array} fuels_ vehicle fuel data
	 * @returns {object} return range
	 */
	_getElectricalRanges(fuels_) {
		let range = {};
		fuels_.forEach(electrical => {
			if (electrical.fuel === 'ELECTRICAL') {
				const unit = OBJECT_UTILS.getNestedObject(electrical, 'range.wltp.unit'.split('.')) || '';
				if (OBJECT_UTILS.getNestedObject(electrical, 'range.wltp.dataSets'.split('.'))) {
					electrical.range.wltp.dataSets.forEach(data => {
						if (data.isDefault) {
							range.city = data.values.city + ' ' + unit;
							range.combined = data.values.combined + ' ' + unit;
						}
					});
				}
			}
		});
		return range;
	}

	/**
	 * get vehicle data
	 * @private
	 * @returns {object} vehicleData json
	 */
	async _getVehicleData() {
		const data = Object.assign({}, this.dataset);
		let vehicleData = {};
		if (data && data.vehicleId) {
			try {
				vehicleData = await vehiclesController.loadVehicleById(data.vehicleId);
			}
			catch (err) {
				console.warn(err);
			}
		}
		return vehicleData;
	}


	/**
	 * render
	 * @private
	 * @returns {void} return nothing
	 */
	_render() {
		if (this._data && !!this._data.efficiencyClass || this._data.consumptionDisplay.length || this._data.emissionDisplay.length) {
			this.innerHTML = template.render(consumptionTilesTemplate, this._data);
			const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
				element: this,
				detail: {element: this}
			});
			document.dispatchEvent(contentRendered);
		}
	}
}

if (window.customElements.get('consumption-tiles-element') === undefined) {
	window.customElements.define('consumption-tiles-element', ConsumptionTilesElement);
}
