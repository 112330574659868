import {template} from 'core-utils';
import {consumptionEmissionLegendTemplate} from './consumption-emission-legend-template';
import {consumptionEmissionState} from './consumption-emission-state';

export default class ConsumptionEmissionLegendElement extends HTMLElement {
	constructor() {
		super();
		// consumptionEmissionState.subscribe(this);
		if (!SETUPS.get('scopes.hide.consumption.legend')) {
			this.innerHTML = template.render(consumptionEmissionLegendTemplate);
		}
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this.innerHTML = '';
	}

	/**
	 * _render
	 * @returns {void} void
	 */
	_render() {
		if (!SETUPS.get('scopes.hide.consumption.legend')) {
			this.innerHTML = template.render(consumptionEmissionLegendTemplate, consumptionEmissionState.state);
		}
	}

	/**
	 * update
	 * @param {object} payloadObject payloadObject
	 * @returns {void} void
	 */
	update(payloadObject) {
		if (payloadObject.payload && payloadObject.payload.contains(this)) {
			this._render();
		}
	}
}

if (window.customElements.get('consumption-emission-legend-element') === undefined) {
	window.customElements.define('consumption-emission-legend-element', ConsumptionEmissionLegendElement);
}
